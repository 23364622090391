<script setup lang="ts">
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import MinusIcon from '@assets/icons/minus-round.svg?component';
import PlusIcon from '@assets/icons/plus-round.svg?component';
import PortraitIcon from '@assets/icons/portrait.svg?component';
import UsersIcon from '@assets/icons/users.svg?component';
import { BaseDialog, BaseButton } from '@components/base';
import { FileInput, FILE_TYPE } from '@models/quiz';
import { useUserStore } from '@stores/user';
import { ref } from 'vue';
import { useToast } from 'vue-toast-notification';

type Props = {
  title: string
  content: FileInput
}

const props = defineProps<Props>();
const emit = defineEmits<{ load: [value: string] }>();
const userStore = useUserStore();
const toast = useToast();

const isModal = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);
const file = ref<File>();
const imageUrl = ref(userStore.files[props.content.fileType as FILE_TYPE]);
const modalImageUrl = ref<string>();
const formData = new FormData();

const icons = {
  [FILE_TYPE.DOCUMENT]: UsersIcon,
  [FILE_TYPE.PHOTO]: PortraitIcon,
  [FILE_TYPE.INSURANCE_FRONT]: UsersIcon,
  [FILE_TYPE.INSURANCE_BACK]: UsersIcon,
};

const showModal = () => isModal.value = true;
const hideModal = () => isModal.value = false;
const clickInput = () => inputRef.value?.click();

const onInputChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  file.value = target.files?.[0];

  if (file.value) {
    modalImageUrl.value = URL.createObjectURL(file.value);
  }

  formData.set(props.content.fileType as string, file.value as File);

  if (inputRef.value) {
    inputRef.value.value = '';
  }
};

const onModalButtonClick = () => {
  if (modalImageUrl.value) {
    file.value = undefined;
    modalImageUrl.value = '';
    return;
  }

  clickInput();
};

const onButtonClick = () => {
  if (!imageUrl.value) {
    showModal();
    return;
  }

  userStore.deleteFile(props.content.fileType as FILE_TYPE);
  imageUrl.value = '';
  modalImageUrl.value = '';
};

const onSubmit = async () => {
  try {
    const result = await userStore.uploadFile(props.content.fileType as FILE_TYPE, formData);
    if (!result?.data) {
      return;
    }

    imageUrl.value = result?.data;
    modalImageUrl.value = '';
    file.value = undefined;
    emit('load', result?.data);
    hideModal();
  } catch (e) {
    toast.error('File upload error');
  }
};
</script>

<template>
  <div class="file-answer sm:f-col-3 f-col-full flex flex-col gap-20">
    <div
      class="text-16"
      v-html="content.mainDescription"
    />

    <figure
      class="relative mt-auto flex h-[240px] w-full cursor-pointer items-center justify-center overflow-hidden
      rounded-[10px] bg-white"
      @click="showModal"
    >
      <component
        :is="icons[content.fileType as FILE_TYPE]"
        v-if="!imageUrl"
        class="text-light-grey"
      />

      <img
        v-else
        class="absolute inset-0 h-full w-full object-contain object-center"
        :src="imageUrl"
        alt=""
      >

      <button
        class="file-answer__btn text-purple hover:text-deep-blue"
        type="button"
        @click.stop="onButtonClick"
      >
        <component :is="imageUrl ? MinusIcon : PlusIcon" />
      </button>
    </figure>
  </div>

  <BaseDialog v-model:visibility="isModal">
    <h1 class="text-37 font-light leading-110">
      {{ title }}
    </h1>

    <div class="mt-50 flex flex-col flex-wrap items-start gap-50 sm:flex-row">
      <figure
        class="file-answer__photo flex w-full grow cursor-pointer items-center justify-center
        overflow-hidden rounded-[10px] bg-light-grey-30 sm:w-auto"
        @click="clickInput"
      >
        <input
          ref="inputRef"
          class="hidden"
          type="file"
          @change="onInputChange"
        >

        <component
          :is="icons[content.fileType as FILE_TYPE]"
          v-if="!modalImageUrl"
          class="text-light-grey"
        />

        <img
          v-else
          class="absolute inset-0 h-full w-full object-contain object-center"
          :src="modalImageUrl"
          alt=""
        >

        <button
          class="file-answer__btn text-purple hover:text-deep-blue"
          type="button"
          @click.stop="onModalButtonClick"
        >
          <component :is="modalImageUrl ? MinusIcon : PlusIcon" />
        </button>
      </figure>

      <div class="shrink-0 sm:w-[228px]">
        <div
          class="text text-16"
          v-html="content.reviewDescription"
        />

        <BaseButton
          class="mt-60 min-w-[209px] bg-purple text-white hover:bg-deep-blue"
          text="Confirm"
          :loading="userStore.loading"
          :disabled="!file"
          animated
          @click="onSubmit"
        >
          <template #icon-right>
            <ChevronRight />
          </template>
        </BaseButton>
      </div>
    </div>
  </BaseDialog>
</template>

<style lang="scss" scoped>
.file-answer__btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 50%;
}

.file-answer__photo {
  position: relative;
  max-width: 402px;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
</style>
