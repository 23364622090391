<script setup lang="ts">
import { apiArticles } from '@api/articles';
import SpinnerIcon from '@assets/icons/spinner.svg?component';
import { useFetch } from '@helpers/use-fetch';
import { ArticlesFilter } from '@models/articles';
import MasonryWall from '@yeger/vue-masonry-wall';
import { debounce } from 'lodash';
import { onMounted, onBeforeUnmount, ref, watch } from 'vue';

type Props = {
  items: any[]
  gap?: number
  category?: { label: string, value: any }
  categoryName: string
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  gap: 20,
  category: () => ({ label: 'All topics', value: '' }),
  categoryName: 'category',
});

const filters = ref<ArticlesFilter>({
  page: 1,
  perPage: 6,
  [props.categoryName as 'category']: '',
});

const articles = ref(props.items);
const loaderRef = ref<HTMLElement | null>(null);
const getArticles = useFetch(apiArticles.getArticles);
const isLastPage = ref(false);
const isLoading = ref(false); // Flag for tracking download status

const addArticles = debounce(async () => {
  if (isLastPage.value || isLoading.value) {
    return;
  }

  const loaderRect = loaderRef.value?.getBoundingClientRect();
  const isBottom = loaderRect!.top - window.innerHeight < 0;
  if (!isBottom) {
    return;
  }

  isLoading.value = true; // Set the download status

  try {
    const response = await getArticles.execute(filters.value);
    articles.value = [...articles.value, ...response.data];
    isLastPage.value = response.pages?.totalCount === articles.value.length;
    filters.value.page += 1;
  } finally {
    isLoading.value = false; // Resetting the download status
  }
}, 100);

watch(() => props.category, async (category) => {
  articles.value = [];
  filters.value[props.categoryName as 'category'] = category.value;
  filters.value.page = 0;

  isLoading.value = true; // Set the download status

  try {
    const response = await getArticles.execute(filters.value);
    articles.value = response.data;
    isLastPage.value = response.pages?.totalCount === articles.value.length;
    filters.value.page += 1;
  } finally {
    isLoading.value = false; // Resetting the download status
  }

  addArticles();
});

onMounted(() => {
  addArticles();
  window.addEventListener('scroll', addArticles);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', addArticles);
});
</script>

<template>
  <div class="base-masonry">
    <MasonryWall
      :items="articles"
      :gap="gap"
    >
      <template #default="{ item, index }">
        <slot
          :item="item"
          :index="index"
        />
      </template>
    </MasonryWall>

    <div
      v-if="!isLastPage || getArticles.loading.value"
      ref="loaderRef"
      class="mt-48 flex justify-center"
    >
      <SpinnerIcon class="h-[32px] w-[32px]" />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
