<script setup lang="ts">
import MinusIcon from '@assets/icons/minus.svg?component';
import PlusIcon from '@assets/icons/plus.svg?component';
import WarningIcon from '@assets/icons/warning.svg?component';
import { BaseButton } from '@components/base';
import { MODAL_TYPE } from '@models/modals';
import { CommerceProduct } from '@models/quiz';
import { computed } from 'vue';

type Props = {
  title: string
  content: CommerceProduct
  active?: boolean
  caption?: string
  label?: string
  price?: string
  image?: string
  url?: string
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
  caption: '',
  label: '',
  price: '',
  image: '',
  url: '',
});

const emit = defineEmits<{ select: [value: boolean] }>();

const columnClass = computed(() => {
  const value = Number(props.content.contentColumns);
  return value ? `sm:f-col-${value}` : 'sm:f-col-3';
});

const caption = computed(() => props.caption || props.content.clarification);
const label = computed(() => props.label || props.content.label);
const price = computed(() => props.price || props.content.price);
const image = computed(() => props.image || props.content.image);
const url = computed(() => props.url || props.content.url);
</script>

<template>
  <div
    class="product-answer f-col-full"
    :class="[columnClass, { 'product-answer--active': active }]"
  >
    <figure
      v-if="image"
      class="product-answer__image row-start-1 row-end-3 sm:mt-24"
    >
      <img
        class="h-full w-full object-contain object-center"
        :src="image"
        alt=""
      >
    </figure>

    <div class="mb-auto items-start justify-between gap-16 sm:order-first sm:flex">
      <div class="flex shrink-0 flex-col items-end gap-4">
        <p
          v-if="label"
          class="rounded-full bg-purple px-22 py-6 text-10 font-bold text-white"
        >
          {{ label }}
        </p>

        <p
          v-if="price"
          class="text-18 font-bold text-purple first:mt-0"
          :class="{'text-15': price.length > 15}"
        >
          {{ price }}
        </p>
      </div>

      <div class="mt-5 sm:order-first sm:mt-0">
        <h2
          v-if="title"
          class="text-20 sm:text-24"
        >
          {{ title }}
        </h2>

        <div
          v-if="caption"
          class="text-14 text-purple sm:text-16"
          v-html="caption"
        />
      </div>
    </div>

    <div
      v-if="content.startsWorkingIn || content.lastsUpTo"
      class="col-start-2 col-end-3 mt-10 flex gap-12 sm:px-20"
    >
      <div class="basis-1/2">
        <p class="text-12 font-medium">
          Starts working in:
        </p>
        <p class="mt-6 text-16 font-bold text-purple sm:text-18">
          {{ content.startsWorkingIn || '-' }}
        </p>
      </div>

      <hr class="product-answer__divider">

      <div class="basis-1/2">
        <p class="text-12 font-medium">
          Lasts up to:
        </p>
        <p class="mt-6 text-16 font-bold text-purple sm:text-18">
          {{ content.lastsUpTo || '-' }}
        </p>
      </div>
    </div>

    <div class="col-start-1 col-end-3 mt-10 flex gap-10 sm:flex-col sm:px-10">
      <BaseButton
        v-if="url"
        class="min-w-[140px] bg-light-grey-30 text-purple hover:bg-deep-blue hover:text-white"
        text="More info"
        size="md"
        @click="$root.setModal(MODAL_TYPE.PRODUCT, true, url)"
      >
        <template #icon-right>
          <WarningIcon />
        </template>
      </BaseButton>

      <div class="hidden sm:block">
        <BaseButton
          class="w-full bg-purple text-white hover:bg-deep-blue hover:text-white"
          :text="active ? 'Remove' : 'Select'"
          @click="emit('select', !active)"
        >
          <template #icon-right>
            <component :is="active ? MinusIcon : PlusIcon" />
          </template>
        </BaseButton>
      </div>

      <div class="grow sm:hidden">
        <BaseButton
          class="w-full bg-purple text-white hover:bg-deep-blue hover:text-white"
          :text="active ? 'Remove' : 'Select'"
          size="sm"
          @click="emit('select', !active)"
        >
          <template #icon-right>
            <component :is="active ? MinusIcon : PlusIcon" />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-answer {
  display: grid;
  grid-template-columns: 140px 1fr;
  column-gap: 10px;
  padding: 10px;
  border-radius: 20px;
  outline: 4px solid transparent;
  outline-offset: -4px;
  background-color: var(--color-white);
  box-shadow: $soft-shadow;
  transition: outline-color 0.3s;

  @include up('sm') {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &--active {
    outline-color: theme('colors.purple');
  }
}

.product-answer__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  border-radius: 15px;
  overflow: hidden;
  background-color: var(--color-light-grey-30);

  @include up('sm') {
    height: 240px;
    border-radius: 26px;
  }
}

.product-answer__divider {
  height: 100%;
  border: none;
  border-left: 2px solid var(--color-light-grey-30);
}
</style>
