<script setup lang="ts">
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import { BaseDialog, BaseButton } from '@components/base';
import { ResetPasswordPayload } from '@models/user';
import { useUserStore } from '@stores/user';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { ref, reactive, computed, watch, nextTick } from 'vue';

type Props = {
  visibility?: boolean
};

const props = defineProps<Props>();
const emit = defineEmits<{
  'update:visibility': [value: boolean],
  success: []
}>();
const userStore = useUserStore();
const inputRef = ref<HTMLElement | null>(null);

const formData = reactive<ResetPasswordPayload>({
  loginName: '',
});

const rules = {
  loginName: { email, required },
};

const v$ = useVuelidate(rules, formData);

const model = computed({
  get() {
    return props.visibility;
  },
  set(value) {
    emit('update:visibility', value);
  },
});

const onSubmit = async () => {
  const isValid = await v$.value.$validate();

  if (!isValid) {
    return;
  }

  const result = await userStore.resetPassword(formData);

  if (result) {
    emit('success');
  }
};

watch(() => props.visibility, async (visibility) => {
  if (!visibility) {
    return;
  }
  await nextTick();
  inputRef.value?.focus();
});
</script>

<template>
  <BaseDialog
    v-model:visibility="model"
    class="reset-password-modal"
    data-testid="reset-password-modal"
  >
    <form @submit.prevent="onSubmit">
      <div class="text-37 font-light">
        Forgot your password?
      </div>

      <p class="mt-40 text-20">
        Please provide your email address, and we will email you reset instructions.
      </p>

      <div class="mt-40 flex flex-col gap-16">
        <input
          ref="inputRef"
          v-model.trim="formData.loginName"
          class="input input--no-shadow"
          :class="{ 'input--error': v$.loginName.$error }"
          type="text"
          placeholder="Email Address"
          data-testid="reset-password-input"
        >
        <p
          v-for="error in v$.loginName.$errors"
          :key="error.$uid"
          class="text-red"
        >
          {{ error.$message }}
        </p>

        <div class="mt-16 flex flex-col items-center justify-center gap-16 sm:mt-0 sm:flex-row">
          <BaseButton
            class="bg-purple text-white hover:bg-white hover:text-deep-blue"
            text="Reset my password"
            type="submit"
            :loading="userStore.loading"
            :disabled="v$.$error"
            animated
            data-testid="reset-password-button-submit"
          >
            <template #icon-right>
              <ChevronRight />
            </template>
          </BaseButton>
        </div>
      </div>
    </form>
  </BaseDialog>
</template>

<style lang="scss" scoped>
.reset-password-modal {
  :deep {
    .base-dialog__content {
      background-color: var(--color-deep-blue);
      color: var(--color-white);

      @include up('lg') {
        max-width: 700px;
        padding: 70px 120px;
      }
    }
  }
}
</style>
