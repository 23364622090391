<script setup lang="ts">
import {  StepContentBody } from '@models/quiz';
import { computed } from 'vue';

type Props = {
  content: StepContentBody,
  answerCount?: number,
  columns?: string,
  image?: string
}

const props = withDefaults(defineProps<Props>(), {
  answerCount: 0,
  columns: '4',
  image: '',
});

const columns = computed(() => Number(props.columns) || 4);

const headerSizeClass = computed(() => {
  if (props.answerCount === 0) {
    return 'text-26 sm:text-40 font-light leading-120';
  }
  if (columns.value >= 8) {
    return 'text-26 sm:text-40 font-light leading-120';
  }
  return 'text-26 sm:text-40 font-light leading-120';
});

const columnClass = computed(() => {
  if (columns.value >= 8) {
    return `sm:f-col-${columns.value} sm:px-60`;
  }
  return `sm:f-col-${columns.value}`;
});
</script>

<template>
  <div
    class="quiz-header flex flex-col gap-30 sm:gap-48"
    :class="{ 'text-center': content.centered }"
  >
    <div
      v-if="content.header"
      class="f-row"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <div :class="{ 'text-center': content.centered2 }">
          <h1
            :class="headerSizeClass"
            data-testid="quiz-header"
            v-html="content.header"
          />
        </div>
      </div>
    </div>

    <div
      v-if="content.subheader"
      class="f-row"
      :class="{ 'order-first': content.centered }"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <p
          class="font-medium text-30"
        >
          {{ content.subheader }}
        </p>
      </div>
    </div>

    <div
      v-if="content.text"
      class="f-row"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <div
          class="text text-16"
          v-html="content.text"
        />
      </div>
    </div>

    <div
      v-if="image"
      class="f-row"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <figure class="quiz-header__image flex justify-center p-30">
          <img
            :src="content.picture"
            alt=""
          >
        </figure>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-header__image {
  border-radius: 40px;
}
</style>
