<script setup lang="ts">
import { StepContentBody } from '@models/quiz';
import { useQuizStore } from '@stores/index';
import { computed } from 'vue';

type Props = {
  content: StepContentBody
}

const props = defineProps<Props>();
const quizStore = useQuizStore();

const loseWeight = computed(() => {
  const finalWeight = quizStore.answerArray
    .find(({ id }) => id === props.content.goal?.[0])
    ?.value || 0;

  return +(weight.value - Number(finalWeight)).toFixed(2);
});

const weight = computed(() => {
  const value = quizStore.answerArray
    .find(({ id }) => id === props.content.bmi?.[0])
    ?.value || 0;

  return Number(value);
});

const height = computed(() => {
  const value = quizStore.answerArray
    .find(({ id }) => id === props.content.bmi?.[1])
    ?.value
    ?.split(/\s/)
    .map((v) => v.replace(/\D/g, ''))
    .map((v) => Number(v) || 0)
|| [0, 0];

  return value[0] * 12 + value[1];
});

const bmi = computed(() => {
  const value = (weight.value / Math.pow(height.value, 2)) * 703;
  return +value.toFixed(2);
});

const achievements = computed(() => {
  return quizStore.answerArray
    .filter(({ id }) => props.content.achievement?.includes(id))
    .map(({ content }) => content[0].content.label);
});

const mostGain = computed(() => {
  return quizStore.answerArray
    .find(({ id }) => props.content.mostGain?.includes(id))
    ?.content[0].content;
});
</script>

<template>
  <div class="quiz-summary">
    <div class="f-row">
      <div class="f-col-full sm:f-col-8 sm:px-40">
        <h1
            class="text-26 sm:text-50 sm:font-extralight sm:leading-none"
            v-html="content.header"
        />
        <p class="text text-16 mt-30 sm:mt-40">
          {{ content.subheader }}
        </p>
      </div>
    </div>

    <div class="f-row mt-30 sm:mt-40 gap-20">
      <div class="lg:f-col-4 w-full">
        <img
          v-if="content.photo"
          class="w-full h-auto rounded-[20px]"
          :src="content.photo"
          alt=""
        >
      </div>
      <div class="lg:f-col-4 w-full">
        <div class="flex gap-20 items-center py-30 px-40 bg-purple rounded-[20px]">
          <div class="min-w-[100px] text-13 text-light-grey leading-140 tracking-130 uppercase">
            Your <br>goal
          </div>
          <div class="text-27 text-white font-medium leading-none">
            Lose {{ loseWeight }} lbs.
          </div>
        </div>
        <div class="flex gap-20 py-30 px-40 bg-white rounded-[20px] mt-20">
          <div class="min-w-[100px] text-13 text-purple leading-140 tracking-130 uppercase">
            Your <br>starting <br>point
          </div>
          <ul class="font-medium w-full">
            <li class="mb-20 pb-20 border-b-[1px] border-light-grey">
              Weight: {{ weight }}lbs.
            </li>
            <li class="mb-20 pb-20 border-b-[1px] border-light-grey">
              BMI of {{ bmi }}
            </li>
            <li class="mb-20 pb-20 border-b-[1px] border-light-grey">
              <div>Have tried:</div>
              <ul class="list-disc">
                <li
                  class="text-14 pt-10 ml-24"
                  v-for="(achievement, index) in achievements"
                  :key="index"
                  v-html="achievement"
                />
              </ul>
            </li>
            <li>
              Most weight gain: {{ mostGain?.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
