import { apiQuiz } from '@api/quiz';
import { getCartItemsFromAnswers } from '@helpers/answers-cart-items';
import { useFetch } from '@helpers/use-fetch';
import { Product } from '@models/product';
import {
  AnswerMap,
  FILE_TYPE,
  QUIZ_STATUS,
  QUIZ_TYPE,
  Step,
  STEP_ANSWER_CONTENT_TYPE,
  STEP_CONTENT_TYPE,
  STEP_TRANSITION_TYPE,
  STEP_TYPE,
  StepAnswer,
} from '@models/quiz';
import { useAnalyticsStore } from '@stores/analytics';
import { useCartStore } from '@stores/cart';
import { useUserStore } from '@stores/user';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

export const useQuizStore = defineStore('quiz', () => {
  const userStore = useUserStore();
  const cartStore = useCartStore();
  const analyticsStore = useAnalyticsStore();

  const steps = ref<Step[]>([]);
  const quizId = ref<number>(0);
  const counter = ref(0);
  const loading = ref(true);
  const answers = ref<AnswerMap>({});
  const prefilledAnswers = ref<{ [stepId: string]: StepAnswer<any> }>({});
  const quizType = ref<QUIZ_TYPE>();
  const submissionId = ref<number|null>(null);
  const status = ref<QUIZ_STATUS>();
  const paid = ref(false);
  const isRenewal = ref(false);
  const treatmentId = ref<number|null>(null);

  const setAnswersApi = useFetch(apiQuiz.setAnswers);
  const abortQuizApi = useFetch(apiQuiz.abortQuiz);
  const setAnswersLoading = computed(() => setAnswersApi.loading);

  const getQuiz = async (type: QUIZ_TYPE, initial = true) => {
    try {
      loading.value = true;

      const urlSearchParam = new URLSearchParams(location.search);
      const prefillValue = urlSearchParam.get('prefill');
      const query = prefillValue ? { prefill: prefillValue } : undefined;
      const response = await apiQuiz.getQuiz(type, {
        ...query,
        'payment-state': urlSearchParam.get('payment-state') ?? undefined,
        'checkout-id': urlSearchParam.get('id') ?? undefined,
      });

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      quizType.value = type;
      steps.value = response.data.quizContent;
      quizId.value = response.data.quizId;
      status.value = response.data.status;
      paid.value = response.data.paid;
      isRenewal.value = response.data.isRenewal;
      treatmentId.value = Number(urlParams.get('treatmentId'));

      if (response.data.submissionId) {
        submissionId.value = response.data.submissionId;
      }
      if (response.data.prefill) {
        prefilledAnswers.value = response.data.prefill;
      }
      if (response.data.submission && !isRenewal.value) {
        answers.value = response.data.submission;
      }

      // prefills product
      Object.entries(prefilledAnswers.value).forEach(([stepId, answer]) => {
        setAnswer([answer], +stepId, true);
      });

      // saves file urls
      steps.value
        .flatMap((step) => step.answers)
        .filter((answer) => answer.content[0].type === STEP_ANSWER_CONTENT_TYPE.FILE_INPUT)
        .forEach((answer) => {
          const type = answer.content[0].content.fileType;
          const url = answer.content[0].content.url as string;
          userStore.files[type as FILE_TYPE] = url;
        });

      // sets last step for completed quiz if not renewal quiz
      if (status.value === QUIZ_STATUS.COMPLETED && !isRenewal.value) {
        counter.value = permittedSteps.value.length - 1;
        return;
      }

      // saves unsaved answers if the history is empty
      if (!response.data.lastStepId && answerArray.value.length !== 0) {
        forceAnswer();
        return;
      }

      // sets next to last answered step if not renewal quiz
      if (!isRenewal.value) {
        counter.value = permittedSteps.value.findLastIndex((step) => {
          return step.id === response.data.lastStepId;
        }) + 1;
      }

      if (counter.value === 0 && initial) {
        analyticsStore.viewContent(type);
      }
    } finally {
      loading.value = false;
    }
  };

  const answerArray = computed(() => Object.values(answers.value).flat());

  const currentStep = computed(() => {
    const step = permittedSteps.value[counter.value];
    const header = step?.content[0].content.header;

    if (!header) {
      return step;
    }

    const variables = header
      ?.match(/{\s*[\w.-]+\s*}/g)
      ?.map((x: string) => x.match(/[\w.-]+/)?.[0]) ?? [];

    const keyValues = variables.map((variable) => {
      const value = answerArray.value.find((answer) => answer.slug === variable)?.value;
      return { variable, value };
    });

    const newHeader = keyValues.reduce((text, { variable, value }) => {
      if (!value) {
        return text;
      }

      return text.replaceAll(`{${variable}}`, value);
    }, header);

    const newStep = cloneDeep(step);
    newStep.content[0].content.header = newHeader;

    return newStep;
  });

  const lastAnsweredStep = computed(() => {
    return permittedSteps.value[counter.value - 1];
  });

  const permittedSteps = computed(() => {
    return steps.value.filter((step) => {
      if (step.type === STEP_TYPE.AUTH && !userStore.info?.isGuest) {
        return false;
      }

      // Skip cart if products have been paid
      if (
        (step.content[0].type === STEP_CONTENT_TYPE.CART_CONTENT && paid.value)
        || (step.content[0].type === STEP_CONTENT_TYPE.STARTER_CONTENT && paid.value)
      ) {
        return false;
      }

      if (step.required.length === 0) {
        return true;
      }

      const answerIds = answerArray.value.map(({ id }) => id);

      return step.required.some((group) => {
        return group.every(({ id }) => answerIds.includes(id));
      });
    });
  });

  const fullName = computed(() => {
    const firstname = answerArray.value
      .find((answer) => answer.slug === 'firstname')
      ?.value || '';

    const lastname = answerArray.value
      .find((answer) => answer.slug === 'lastname')
      ?.value || '';

    return `${firstname} ${lastname}`;
  });

  const weight = computed(() => {
    const value = answerArray.value
      .find(({ slug }) => slug === 'weight')
      ?.value || 0;

    return Number(value);
  });

  const setAnswer = async (payload: StepAnswer<any>[], stepId?: number, prefilled?: boolean) => {
    const prevAnswers = stepId ? answers.value[stepId] : null;

    const id = stepId ?? lastAnsweredStep.value?.id;
    answers.value = { ...answers.value, [id]: [...payload] };

    // eslint-disable-next-line no-console
    // console.log('payload:', payload);

    // eslint-disable-next-line no-console
    // console.log('answers:', answers.value);

    if (userStore.info?.isGuest) {
      return true;
    }

    const response = await setAnswersApi.execute({
      quizType: quizType.value as QUIZ_TYPE,
      quizId: quizId.value,
      status: QUIZ_STATUS.IN_PROGRESS,
      content: answers.value,
      lastStepId: prefilled ? 0 : id,
      aid: analyticsStore.aid,
    });

    if (setAnswersApi.error.value?.status === 403) {
      location.reload();
    }

    if (response?.data.submissionId) {
      submissionId.value = response.data.submissionId;
    }

    trackSetAnswer(payload, prevAnswers);

    // Because current api returns null on failure
    return !!response;
  };

  // Product selection analytics between steps
  const trackSetAnswer = (answers: StepAnswer<any>[], prevAnswers?: StepAnswer<any>[] | null) => {
    const products = answers
      .map((answer) => {
        if (!answer.content[0].content.product?.id) {
          return null;
        }
        return answer.content[0].content.product;
      })
      .filter((product) => product) as Product[];

    const removedProducts = (prevAnswers ?? [])
      .map((answer) => {
        if (!answer.content[0].content.product?.id) {
          return null;
        }
        return answer.content[0].content.product;
      })
      .filter((product) => product)
      .filter((product) => !products.find((p) => p.id === product.id));

    // Product removing event from answers
    if (removedProducts.length) {
      analyticsStore.removeFromCart(
        cartStore.productsToAnalyticProducts(removedProducts, 1),
        quizTotal.value / 100,
      );
    }

    // Product adding event from answers
    if (products.length) {
      analyticsStore.addToCart(
        cartStore.productsToAnalyticProducts(products, 1),
        quizTotal.value / 100,
      );
    }
  };

  const forceAnswer = () => setAnswer(answerArray.value);

  const next = () => (counter.value += 1);

  const back = () => {
    let stepIndex = counter.value - 1;
    let skipCounter = 1;

    while (
      stepIndex > 0
      && (permittedSteps.value[stepIndex]?.transition === STEP_TRANSITION_TYPE.TIMEOUT
        || permittedSteps.value[stepIndex]?.type === STEP_TYPE.AUTH)
    ) {
      stepIndex -= 1;
      skipCounter += 1;
    }

    counter.value -= skipCounter;
  };

  const restart = async () => {
    counter.value = 0;
    answers.value = {};

    if (userStore.info?.isGuest) {
      return;
    }

    const response = await setAnswersApi.execute({
      quizType: quizType.value as QUIZ_TYPE,
      quizId: quizId.value,
      status: QUIZ_STATUS.IN_PROGRESS,
      content: null,
      lastStepId: null,
      aid: analyticsStore.aid,
    });

    if (setAnswersApi.error.value?.status === 403) {
      location.reload();
    }

    if (!response?.data) {
      return;
    }

    steps.value = response?.data.quizContent;
    quizId.value = response?.data.quizId;

    if (response?.data.submissionId) {
      submissionId.value = response?.data.submissionId;
    }
  };

  const abort = async () => {
    const redirect = () => {
      window.history.back();
    };

    if (!confirm('All your submitted data will be deleted completely. Do you wish to continue?')) {
      return;
    }

    if (userStore.info?.isGuest) {
      redirect();
      return;
    }

    const response = await abortQuizApi.execute(quizType.value as QUIZ_TYPE);

    if (response?.data.success) {
      redirect();
    }
  };

  const addAnswerProductsToCart = (answersValue: AnswerMap = answers.value) => {
    if (!submissionId.value) {
      return;
    }

    const items = getCartItemsFromAnswers(steps.value, answersValue);

    return cartStore.update({
      items: items.map((item) => ({
        productId: item.productId,
        variantId: item.variantId,
        quantity: item.quantity,
        dosage: item.dosage,
        dosageUnit: item.dosageUnit,
      })),
      submissionId: submissionId.value,
      eventId: crypto.randomUUID(),
    });
  };

  watch(counter, (counterValue) => {
    if (userStore.info?.isGuest) {
      return;
    }

    // Completes quiz if step is the second last
    if (counterValue === permittedSteps.value.length - 2) {
      setAnswersApi.execute({
        quizType: quizType.value as QUIZ_TYPE,
        quizId: quizId.value,
        status: QUIZ_STATUS.COMPLETED,
        content: answers.value,
        lastStepId: currentStep.value.id,
        aid: analyticsStore.aid,
        treatmentId: treatmentId.value,
      });
    }
  });

  watch(currentStep, async (currentStepValue, oldStepValue) => {
    if (currentStepValue?.id === oldStepValue?.id) {
      return;
    }

    // deletes unnecessary answers
    Object.keys(answers.value).forEach((stepId) => {
      const isPrefilled = !!prefilledAnswers.value[stepId];
      const isPermitted = !!permittedSteps.value.find((step) => step.id === +stepId);
      if (!isPermitted && !isPrefilled) {
        delete answers.value[stepId];
      }
    });

    if (
      currentStepValue?.type === STEP_TYPE.SUMMARY
      && currentStepValue?.content[0].type === STEP_CONTENT_TYPE.CART_CONTENT
    ) {
      addAnswerProductsToCart();
    }

    // Add starter products to cart
    if (currentStepValue?.content[0].type === STEP_CONTENT_TYPE.STARTER_CONTENT) {
      addAnswerProductsToCart({ [currentStepValue.id]: currentStepValue.answers });
    }
  });

  const quizTotal = computed(() => {
    const items = getCartItemsFromAnswers(steps.value, answers.value);
    return items.reduce((total, item) => total + (item.variant?.variantAmount || 0), 0);
  });

  return {
    steps,
    permittedSteps,
    counter,
    getQuiz,
    loading,
    setAnswersLoading,
    currentStep,
    next,
    back,
    restart,
    abort,
    answers,
    answerArray,
    setAnswer,
    forceAnswer,
    submissionId,
    quizType,
    status,
    paid,
    fullName,
    weight,
    addAnswerProductsToCart,
    quizTotal,
  };
});
