<script setup lang="ts">
import { computed } from 'vue';

type Props = {
  image?: string
  label?: string
  active?: boolean
  href?: string
}

const props = withDefaults(defineProps<Props>(), {
  image: '',
  label: '',
  active: false,
  href: undefined,
});

const emit = defineEmits<{ click: [] }>();
const tag = computed(() => (props.href ? 'a' : 'button'));
</script>

<template>
  <div class="quiz-icon-card">
    <component
      :is="tag"
      class="quiz-icon-card__btn"
      :type="tag === 'button' ? 'button' : undefined"
      :href="href"
      @click="emit('click')"
    >
      <span
        v-if="image"
        class="quiz-icon-card__image"
      >
        <img
          :src="image"
          :alt="label"
        >
      </span>

      <span
        v-if="label"
        class="quiz-icon-card__heading"
      >
        <span class="text-16 font-medium">
          {{ label }}
        </span>
      </span>
    </component>
  </div>
</template>

<style lang="scss" scoped>
.quiz-icon-card__heading {
  padding-bottom: 10px;
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 0;
    background-color: theme('colors.light-grey');
    bottom: 0;
    left: 50%;
    right: 50%;
    transition-duration: 0.3s;
    transition-property: height, left, right;
  }
}
.quiz-icon-card__image {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 100%;
  background-color: theme('colors.purple');
  width: 60px;
  height: 60px;
  position: absolute;
  top: 4px;
  left: 6px;
  transition-duration: 0.3s;
  transition-property: background-color, top;

  @include up('sm') {
    width: 100px;
    height: 100px;
    left: 50%;
    margin-left: -50px;
    top: -40px;
  }

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    object-position: center;

    @include up('sm') {
      width: 48px;
      height: 48px;
    }
  }
}
.quiz-icon-card__btn {
  display: block;
  position: relative;
  width: 100%;
  padding: 25px 40px 15px 80px;
  border-radius: 40px;
  background-color: theme('colors.white');
  text-align: left;

  @include up('sm') {
    gap: 26px;
    padding: 75px 0 15px 0;
    border-radius: 20px;
    margin-top: 40px;
    text-align: center;
  }
  &:hover {
    .quiz-icon-card__heading {
      &:after {
        left: 0;
        right: 0;
        height: 1px;
      }
    }
    .quiz-icon-card__image {
      top: -6px;
      background-color: theme('colors.charcoal');
      @include up('sm') {
        top: -50px;
      }
    }
  }
}
</style>
