<script setup lang="ts">
import ChevronDown from '@assets/icons/chevron-down.svg?component';
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import CrossIcon from '@assets/icons/cross.svg?component';
import SimpleCheck from '@assets/icons/simple-check.svg?component';
import { BaseButton } from '@components/base';
import { CommercePlan } from '@models/quiz';
import gsap from 'gsap';
import { ref, watch } from 'vue';

type Props = {
  title: string
  content: CommercePlan
  active?: boolean
}

withDefaults(defineProps<Props>(), {
  active: false,
});

const emit = defineEmits<{ select: [] }>();
const detailsActive = ref(false);
const detailsRef = ref<HTMLElement | null>(null);

watch(detailsActive, (active) => {
  gsap.to(detailsRef.value, {
    height: active ? 'auto' : 0,
    duration: 0.4,
    ease: 'sine.out',
  });
});
</script>

<template>
  <div
    class="plan-answer f-col-full sm:f-col-3 flex flex-col justify-between"
    :class="{ 'plan-answer--active': active }"
  >
    <div>
      <p
        v-if="content.label"
        class="plan-answer__tag rounded-full bg-purple px-22 py-6 text-10 font-bold text-white"
      >
        {{ content.label }}
      </p>

      <h2
        v-if="title"
        class="text-24 sm:text-37 sm:font-light sm:leading-110"
      >
        {{ title }}
      </h2>

      <p
        v-if="content.price"
        class="text-18 font-bold text-purple first:mt-0 sm:mt-10"
      >
        {{ content.price }}
      </p>

      <p
        v-if="content.description"
        class="mt-8 text-16 first:mt-0 sm:mt-30 sm:text-20"
      >
        {{ content.description }}
      </p>
    </div>

    <div>
      <div
        ref="detailsRef"
        class="h-0 overflow-hidden sm:!h-auto"
      >
        <ul class="mt-20 flex flex-col gap-12 text-14 sm:mt-30">
          <li
            v-for="(item, index) in content.planItems"
            :key="index"
            class="flex items-start gap-14"
            :class="{ 'text-grey': !item.active }"
          >
            <SimpleCheck
              v-if="item.active"
              class="mt-[-0.25em] shrink-0 text-purple"
            />
            <CrossIcon
              v-else
              class="mt-[-0.25em] shrink-0 text-grey"
            />
            <span
              class="plan-answer__text"
              v-html="item.item"
            />
          </li>
        </ul>
      </div>

      <div class="mt-30 hidden sm:block">
        <BaseButton
          class="min-w-[145px] bg-purple text-white hover:bg-deep-blue"
          text="Select"
          animated
          @click="emit('select')"
        >
          <template #icon-right>
            <ChevronRight />
          </template>
        </BaseButton>
      </div>

      <div class="mt-20 flex gap-10 sm:hidden">
        <BaseButton
          class="grow bg-light-grey text-white hover:bg-deep-blue"
          :class="{ '!bg-deep-blue': detailsActive }"
          text="Details"
          size="sm"
          @click="detailsActive = !detailsActive"
        >
          <template #icon-right>
            <ChevronDown :class="{ 'rotate-180': detailsActive }" />
          </template>
        </BaseButton>

        <BaseButton
          class="grow bg-purple text-white hover:bg-deep-blue"
          text="Select"
          animated
          size="sm"
          @click="emit('select')"
        >
          <template #icon-right>
            <ChevronRight />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.plan-answer {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  outline: 4px solid transparent;
  outline-offset: -4px;
  background-color: var(--color-white);
  box-shadow: $soft-shadow;
  transition: outline-color 0.3s;

  @include up('sm') {
    padding: 40px 40px 20px;
    border-radius: 40px;
  }

  &--active {
    outline-color: theme('colors.purple');
  }
}

.plan-answer__tag {
  position: absolute;
  top: 20px;
  right: 20px;

  @include up('sm') {
    top: 30px;
    right: 30px;
  }
}

.plan-answer__text:deep(a) {
  color: theme('colors.purple');

  &:hover {
    text-decoration: underline;
  }
}
</style>
