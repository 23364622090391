<script setup lang="ts">

import { StepContentBody } from '@models/quiz';
import { QuizNavigation } from './index';

type Props = {
  content: StepContentBody
  backHidden?: boolean
}

withDefaults(defineProps<Props>(), {
  backHidden: false,
});

const emit = defineEmits<{
  back: [],
  next: []
}>();
</script>

<template>
  <div class="quiz-image-text">
    <div class="sm:flex gap-x-20 lg:gap-x-[130px] justify-center items-center">
      <div
        v-if="content.header"
        class="lg:w-[390px]"
      >
        <h1 class="text-50 sm:text-60 font-light leading-none">
          {{ content.header }}
        </h1>

        <div
          v-if="content.text"
          class="text mt-30 text-16 first:mt-0 sm:mt-55"
          v-html="content.text"
        />

        <div class="mt-30">
          <QuizNavigation
            class="!justify-start"
            :back-hidden="backHidden"
            @next="emit('next')"
            @back="emit('back')"
          />
        </div>
      </div>
      <div class="mt-40 sm:mt-0 lg:w-[400px]">
        <img
          v-if="content.photo"
          class="w-full sm:max-w-[400px] h-auto"
          :src="content.photo"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
